<template>
  <section
    v-if="props.show"
    id="modalFlik"
    class="fixed top-0 z-30 w-full h-screen -translate-x-1/2 modal left-1/2"
  >
    <div
      class="fixed top-0 left-0 z-40 w-full h-screen bg-black/60"
      @click="props.closeOutside ? emit('close') : ''"
    ></div>
    <div :class="[props.classContent, clsModal]" class="modalin-center">
      <div v-if="props.showClose" class="flex items-center justify-between">
        <div class="w-[95%]">
          <slot name="title"></slot>
        </div>
        <button class="text-gray-600" @click="$emit('close')">
          <LazyIconsClose class="w-5 h-5 text-gray-600" />
        </button>
      </div>
      <div
        v-if="line"
        class="my-[12px] -mx-[16px] h-[1px] border-[1px]"
        :class="props.line ? 'border-gray-200' : 'border-transparent'"
      />
      <div :class="clsContent" class="overflow-y-auto scrollbar-hide">
        <slot name="icon"></slot>
        <slot name="info"></slot>
        <slot name="addinfo"></slot>
      </div>
      <slot name="footer"></slot>
    </div>
  </section>
</template>
<script lang="ts" setup>
const props = defineProps({
  line: {
    type: Boolean,
    default: true,
  },
  show: {
    type: Boolean,
    default: true,
  },
  showClose: {
    type: Boolean,
    default: true,
  },
  closeOutside: {
    type: Boolean,
    default: true,
  },
  message: {
    type: String,
    default: "Continue payment ?",
  },
  classContent: {
    type: String,
    default: "bg-white",
  },
});

const emit = defineEmits(["close"]);

const { isDesktopOrTablet, isMobileOrTablet, isIos } = useDevice();

const clsModal = computed(() => {
  let cls = "";
  if (isDesktopOrTablet) {
    cls = "w-[480px]";
  }
  if (isIos && isMobileOrTablet) {
    cls = "modal-ios ";
  }
  return cls;
});

const clsContent = computed(() => {
  let cls = "";
  if (isDesktopOrTablet) {
    cls = "modal-content";
  }
  if (isMobileOrTablet) {
    cls = "modal-content";
  }
  if (isIos && isMobileOrTablet) {
    cls = "modal-content-ios";
  }
  return cls;
});
</script>

<style scoped>
.modal-ios {
  @apply h-[calc(100vh-100px)] bg-gray-100;
}
.modalin-center {
  @apply top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 absolute z-[1066];
  @apply mx-auto p-5 rounded-2xl;
  @apply flex flex-col justify-between;
  @apply xs:w-11/12 sm:w-full md:max-w-md max-h-[96%];
}
.modal-content {
  @apply overflow-y-scroll;
}
</style>
